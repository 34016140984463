import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Oac from "../images/oac.svg";
import Saaac from "../images/saaak-1.svg";
// import Unity from "../images/unity.png";



const About = () => (
          <Layout>
   <SEO title="About" />

    <Container className='dark-green-b skin' fluid > 
    <div className='sections-top-gap'></div>

<Container>
      <Row className='about pt-5'>
        <Col xs={12} md={6} className='pr-5 mb-5'>
          <h1 >
          Acknowledgment
          </h1>

          <p className='pt-3'>
          I was first introduced to sensory storytelling during my time at Mencap, the UK’s leading charity for people with learning disabilities. I worked on <span className='italic'>Bags of Ability</span> as a Sensory Storyteller, where my role involved visiting daycares, schools, and community centres to lead workshops for children and their parents or caregivers. 
          I loved working with children and using this sensory storytelling approach to support their early literacy and speech development skills. 
          The importance of working alongside parents and caregivers to strengthen their storytelling skills and confidence was an unexpected outcome for me and one I really valued. 
          During this project, I trained with Dr. Nicola Grove, founder of OpenStorytellers, and she taught me the importance of telling stories with children, instead of telling stories to children. 
          <span className='italic'>The Storytelling Project at SAAAC Autism Centre </span>was inspired by my work and training at Mencap.
          <br/>  <br/>
          Meeting and working with Geetha Moorthy, Founder and Executive Director of SAAAC Autism Centre has been a tremendous opportunity. 
          Geetha understands and celebrates the importance of the arts in education, and with her support and encouragement The Storytelling Project at SAAAC Autism Centre was born. 
          <br/>  <br/>
          Thank you to our Program Partners: The Ontario Arts Council and Unity for Autism. These great organizations believed in our project and provided funding to make
          it possible. 
          <br/>  <br/>
          A heartfelt thank you to the following believers and collaborators: Geetha Moorthy, Mike Lake, Jillian Pearson, Koby Barhad, Leyland Cecco, Jean Hage and the team at SAAAC Autism Centre.
          <br/>  <br/>
          <span className='bold'>Carrie Hage:</span> <br/>
          Artist Educator and Creator of <span className='italic'>The Storytelling Project at SAAAC Autism Centre </span>
          </p>
        </Col>
       

        <Col className=''>
          <h1>
          SAAAC Autism Centre
          </h1>
          <p className='pt-3'>

          SAAAC Autism Centre was created with the intention of inspiring families in need of access to autism services, with
          a special focus on serving culturally and linguistically diverse families in need of support.
          The organization looks to create positive change through:
</p>
<ul>

  <li>
  Encouraging lifelong development of the children, youth, and adults they serve through diverse therapies and programs
  </li>
  <li>
  Empowering parents through education, training,
          and peer support networks

  </li>
  <li>
  Engaging communities through meaningful volunteer experiences and fostering greater understanding
          and acceptance of autism

  </li>
  <li>
  Providing access for all through community engagement and knowledge sharing
</li>
</ul>
<p>
          More info: www.saaac.org
          </p>
<br/>
          <div className='d-flex justify-content-start mt-3 pb-5'> 
            <Oac className='mr-4'/> 
            <Saaac/>
          </div>

        </Col>
      </Row>
      </Container>
    </Container>

   </Layout>

    )
 
export default About
